<template>
	<div class="notice_popup view info" style="width:1350px; height:700px; margin-top:-400px;">
		<div class="close">
			<img src="/images/close.png" alt="닫기" @click="$emit('close')"/>
		</div>
		<div class="view-tit">프로젝트 찾기</div>
		<!-- <div class="mem_count"><img src="/images/admin/logo_sm.png" alt="하이프로"/>{{forwardData.div == 'adm' ? '관리자번호':'회원번호'}}<span class="count">{{mberInfo.mberSeq}}</span></div> -->
		<!-- <div class="view">
			<div class="list">
				<div class="label">성명</div><div class="contents">{{mberInfo.mberNm}}</div><div class="mf_btn" v-if="'agt,adm'.indexOf(forwardData.div) > -1" @click="pageMove(mberInfo.mberSeq)">정보수정 &#9654;</div>
			</div>
			<div class="list">
				<div class="label"><span v-if="'agt,adm'.indexOf(forwardData.div) > -1">담당자 아이디</span><span v-else>회원아이디</span></div><div class="contents">{{mberInfo.loginId}}</div>
			</div>
			<div class="list">
				<div class="label">휴대폰번호</div><div class="contents">{{mberInfo.hpNo | cell}}</div>
			</div>
			<div class="list">
				<div class="label">이메일</div><div class="contents">{{mberInfo.email}}</div>
			</div>
			<div class="list" v-if="'agt,adm'.indexOf(forwardData.div) < 0">
				<div class="label">이메일 수신여부</div><div class="contents">{{mberInfo.emailRecvYn == 'Y' ? '동의':'미동의'}}</div>
			</div>
			<div class="list" v-if="'agt,adm'.indexOf(forwardData.div) < 0">
				<div class="label">SMS 수신여부</div><div class="contents">{{mberInfo.smsRecvYn == 'Y' ? '동의':'미동의'}}</div>
			</div>
			<div class="list" v-if="forwardData.div == 'tec'">
				<div class="label">회원소속사</div><div class="contents">{{mberInfo.corpNm || '-'}}</div>
			</div>

			<div class="list" v-if="forwardData.div == 'tec'">
				<div class="label">직원구분</div><div class="contents"><SelectComp type="text" cdId="PRO102" v-model="mberInfo.empDivCd" /></div>
			</div>
			<div class="list" v-if="forwardData.div == 'tec'">
				<div class="label">생년월일</div><div class="contents">{{mberInfo.birthYyyymmdd | date('yyyy.MM.dd')}}</div>
			</div>

			<div class="list" v-if="'tec,agt,adm'.indexOf(forwardData.div) < 0">
				<div class="label">회사명</div><div class="contents">{{mberInfo.corpNm}}</div>
			</div>
			<div class="list" v-if="forwardData.div == 'pmg'">
				<div class="label">부서명</div><div class="contents">{{mberInfo.deptNm}}</div>
			</div>
			<div class="list" v-if="'pmg,agt,adm'.indexOf(forwardData.div) > -1">
				<div class="label">직급</div><div class="contents">{{mberInfo.positionNm}}</div>
			</div>
			<div class="list" v-if="'agt,adm'.indexOf(forwardData.div) > -1">
				<div class="label">담당분야</div><div class="contents"><SelectComp type="text" cdId="PRO101" v-model="mberInfo.mberDivCd"/></div>
			</div>
			<div class="list" v-if="'tec,agt,adm'.indexOf(forwardData.div) > -1">
				<div class="label">성별</div><div class="contents">{{mberInfo.gender | gender}}</div>
			</div>
		</div> -->
		<div class="view">
			<div class="search-box" style="margin-left:0px;">
				<InputComp title="프로젝트명" v-model="findProjectNm" @keydown.enter="getList()"/>
				<img class="search_btn" src="/images/admin/search_btn.png" alt="검색버튼" @click="getList()"/>
			</div>

			<div class="Board type3" style="margin-left:-163px;">
				<table class="Board_type3 admin">
					<colgroup>
						<col width="3%">
						<col width="5%">
						<col width="5%">
						<col width="9%">
						<col width="6%">
						<col width="5%">
						<col width="5%">
						
					</colgroup>
					<thead>
						<tr>
							<th>NO</th>
							<th>회원명</th>
							<th>회원번호</th>
							<th>아이디</th>
							<th>가입일</th>
							<th>기술등급<br/>(학력기준)</th>
							<th>기술등급<br/>(자격기준)</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td class="score"></td>
							<td class="score"></td>
							<td class="score"></td>
							<td class="score"></td>
							<td class="score"></td>
							<td class="score"></td>
							<td class="score"></td>
						</tr>
						<!-- <template v-if="list && list.length > 0">
							
							<tr v-for="(item, idx) in list" :key="item.mberSeq" :class="{new:isNew(item)}">
								<td class="score"><div class="new" v-if="isNew(item)" style="font-size:10px;">미평가</div>{{(pageInfo.pageUnit * (pageInfo.pageIndex-1)) + idx + 1}}</td>
								<td class="score name" @click="popupMberInfo(item.mberSeq, 'tec')">{{item.mberNm}} &#9654;</td>
								<td class="score">{{item.mberSeq}}</td>
								<td class="score">{{item.loginId}}</td>
								<td class="score">{{item.joinDate | date('yyyy.MM.dd')}}</td>
								<td class="score"><grade-img type="SCHOOL_TECH_ADM" :grade="item.resumeSchoolTechGradeCd"/></td>
								<td class="score"><grade-img type="LICENSE_TECH_ADM" :grade="item.resumeLicenseTechGradeCd"/></td>
								<td class="score"><cfm-status :value="item.techLevelState"/></td>
								<td class="score"><cfm-status :value="item.dutyState"/></td>
								<td class="score"><cfm-status :value="item.expertState"/></td>
								<td class="score"><cfm-status :value="item.schoolState"/></td>
								<td class="score"><cfm-status :value="item.licenseState"/></td>
								<td class="score"><cfm-status :value="item.eduState"/></td>
								<td class="score"><cfm-status :value="item.attaFileState"/></td>
								<td class="score"><cfm-status :value="item.careerState"/></td>
								<td class="score"><cfm-status :value="item.projectState"/></td>
								<td class="score resume">
									<div class="btn" v-if="item.resmYn == 'Y'" @click="goResume(item)">이력서 검토 &#9654;</div>
									<div class="btn rq" v-else @click="reqResume(item)">등록요청 ▶</div>
								</td>
							</tr>
						</template>
						<tr v-else>
							
							<td colspan="17" class="none">등록된 회원이 없습니다!</td>
						</tr> -->
					</tbody>
				</table>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props : ['param'],
	data() {
		return {
			findProjectNm : '',
			mberInfo : {},

			forwardData : this.param,
		}
	},

	mounted() {
		//console.log('param', this.param);
		
		//this.getDcoGmgInfo()
	},

	methods : {
		getDcoGmgInfo() {
			this.$.httpPost('/api/mem/adm/dco/getDcoGmgInfo', this.forwardData)
				.then(res => {
					this.mberInfo = res.data.mberInfo;
					
					this.$emit('updated');
				}).catch(this.$.httpErrorCommon);
		},

		pageMove(seq) {
			this.$router.push({name:'MEM970M03', params:{admMberSeq:seq}});
			this.$emit('close');
		}
	}
}
</script>